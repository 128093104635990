import { http, createConfig } from "@wagmi/core";
import { mainnet } from "@wagmi/core/chains";
import { injected } from "@wagmi/connectors";

export const config = createConfig({
    chains: [mainnet],
    connectors: [injected()],
    transports: {
        [mainnet.id]: http(
            "https://mainnet.infura.io/v3/23a8cc2dcea043ad9308eea6f23e4d8f"
        ),
    },
    ssr: true,
});
