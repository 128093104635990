/* eslint-disable no-undef */
import {
  writeContract,
  getBalance,
  watchContractEvent,
  watchConnections,
} from "@wagmi/core";
import { getAccount, disconnect, multicall } from "@wagmi/core";
import { ethers } from "ethers";

import "./page2.css";

import ellipse from "@/assets/imgs/page2/icon_slg.jpg";
import ellipse2 from "@/assets/imgs/page2/icon_game.jpg";
import card_top_icon from "@/assets/imgs/page2/card_top_icon.png";
import card_bottom_icon from "@/assets/imgs/page2/card_bottom_icon.png";
import { config } from "../../config";
import { useState, useEffect } from "react";
import { mainnet } from "viem/chains";
import { notification } from "antd";

const contractGameAddress = "0xA32899Ece7A1ECA8a5d68b0547ad6044D6B7a0ad";
const gameTokenAddress = "0x825459139C897D769339f295E962396C4F9E4A4D";
//const activeTokenAddress = "0x331E3Bd73aD3134037bf6fb7a0213785c3962972";
// const contractCRE = "0x115eC79F1de567eC68B7AE7eDA501b406626478e";
// const contractSLG = "0xFc0B60E0DF5Dc9d4B72D957cA2d251ceE308019a";

const abi = [
  {
    inputs: [{ internalType: "uint256", name: "amountCRE", type: "uint256" }],
    name: "swapCRE",
    outputs: [{ internalType: "uint256", name: "amountGame", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountSLG",
        type: "uint256",
      },
    ],
    name: "swapSLG",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

const creAbi = [
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "owner", type: "address" },
      { indexed: true, name: "spender", type: "address" },
      { indexed: false, name: "value", type: "uint256" },
    ],
    name: "Approval",
    type: "event",
  },
];

const swapEventAbi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Swap",
    type: "event",
  },
];

const Page2 = () => {
  const SWAP_STATUS_IDLE = "idle";
  const SWAP_STATUS_ONGOING = "ongoing";

  const [showPop1, setShowPop1] = useState(false);
  const [account, setAccount] = useState("");
  const [CREAmount, setCREAmount] = useState("--");
  const [GAMEOwned, setGAMEOwned] = useState("--");
  const [input_info, setInput_info] = useState("");
  const [GAMEAmount, setGAMEAmount] = useState("- -");
  const [activeToken, setActiveToken] = useState("SLG");
  const [activeTokenAddress, setActiveTokenAddress] = useState("");
  const [btn_info, setBtn_info] = useState("Connect Wallet");
  const [token_info, setToken_info] = useState("SLG");
  const [ratioText, setRatioText] = useState("1 SLG = 11.4196 GAME");
  const [swapStatus, setSwapStatus] = useState(SWAP_STATUS_IDLE);

  const optData = ["CRE", "SLG"];
  const [optStatus, setOptStatus] = useState("SLG");

  useEffect(() => {
    // console.log("optData has been updated:", optStatus);
    // console.log(optStatus === "SLG");

    if (optStatus === "CRE") {
      setActiveTokenAddress("0x115eC79F1de567eC68B7AE7eDA501b406626478e");
      setToken_info("CRE");
      setRatioText("1 CRE = 1 GAME");
      setActiveToken("CRE");
    } else if (optStatus === "SLG") {
      setActiveTokenAddress("0xFc0B60E0DF5Dc9d4B72D957cA2d251ceE308019a");
      setToken_info("SLG");
      setRatioText("1 SLG = 11.4196 GAME");
      setActiveToken("SLG");
    }
  }, [optStatus]);

  useEffect(() => {
    const walletAccount = getAccount(config);
    if (walletAccount.address !== undefined) {
      getActiveTokenBalanceOfWallet(walletAccount.address);
    }
  }, [GAMEOwned]);

  useEffect(() => {
    const walletAccount = getAccount(config);
    setCREAmount("--");
    setGAMEAmount("- -");
    // console.log("active token address in use effect:", activeTokenAddress);
    if (walletAccount.address !== undefined) {
      // console.log("Getting Balance again");
      getActiveTokenBalanceOfWallet(walletAccount.address);
    }
  }, [activeTokenAddress]);

  useEffect(() => {
    const disconnectWallet = async () => {
      try {
        await disconnect(config);
        // console.log("Wallet disconnected");
        setAccount("Connect Wallet");
        setCREAmount("--");
        setGAMEAmount("- -");
        setBtn_info("Connect Wallet");
      } catch (error) {
        // console.log("Error disconnecting wallet:", error);
      }
    };

    disconnectWallet();
  }, []);

  const unwatchWatchConnections = watchConnections(config, {
    onChange(data) {
      // console.log("Connections changed!", data[0].accounts[0]);
      if (data[0].accounts[0] !== undefined) {
        const walletAccount = getAccount(config);
        // Set the button text to a short version of the wallet address
        const shortAddress = `${walletAccount.address.slice(
          0,
          6
        )}...${walletAccount.address.slice(-4)}`;
        setBtn_info(shortAddress);

        setAccount(walletAccount.address);
        getAllBalance(walletAccount.address);
      } else {
        // console.log("Account is not available");
        setAccount("Connect Wallet");
      }
    },
  });

  const getAllBalance = async (walletAddress) => {
    try {
      const result = await multicall(config, {
        contracts: [
          {
            // get CRE/SLG Balance
            address: activeTokenAddress,
            functionName: "balanceOf",
            abi: creAbi,
            args: [walletAddress],
          },
          {
            // get GAME Balance
            address: gameTokenAddress,
            functionName: "balanceOf",
            abi: abi,
            args: [walletAddress],
          },
        ],
      });

      const creamount = Number(result[0].result) / 1000000000000000000;
      const gameamount = Number(result[1].result) / 1000000000000000000;
      setCREAmount(creamount.toFixed(4));

      if (optStatus === "CRE") {
        setGAMEAmount(creamount.toFixed(4));
      } else if (optStatus === "SLG") {
        setGAMEAmount(creamount.toFixed(4) * 11.4196394);
      }

      setGAMEOwned(gameamount.toFixed(4));
    } catch (error) {
      // console.log("error", error);
    }
  };

  const getActiveTokenBalanceOfWallet = async (walletAddress) => {
    // console.log("getActiveTokenBalanceOfWallet:", walletAddress);
    // console.log("active token address:", activeTokenAddress);
    try {
      const balance = await getBalance(config, {
        address: walletAddress,
        token: activeTokenAddress,
        chainId: mainnet.id,
      });
      const formattedBalance = parseFloat(balance.formatted).toFixed(4);
      setCREAmount(formattedBalance);
      if (optStatus === "CRE") {
        setGAMEAmount(formattedBalance);
      } else if (optStatus === "SLG") {
        setGAMEAmount(formattedBalance * 11.4196394);
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  const getGameBalanceOfWallet = async (walletAddress) => {
    try {
      const balance = await getBalance(config, {
        address: walletAddress,
        token: gameTokenAddress,
        chainId: mainnet.id,
      });
      // console.log("balance getting game balance");
      const formattedBalance = parseFloat(balance.formatted).toFixed(4);
      setGAMEOwned(formattedBalance);
    } catch (error) {
      // console.log("error", error);
    }
  };

  const swapSLG = async (amount) => {
    try {
      console.log("swapSLG amount: ", amount);
      const response = await writeContract(config, {
        abi: abi,
        address: contractGameAddress,
        functionName: "swapSLG",
        args: [amount],
      });
      const unwatch = watchContractEvent(config, {
        address: contractGameAddress,
        abi: swapEventAbi,
        eventName: "Swap",
        onLogs(logs) {
          console.log("New logs!", logs[0].data);
          unwatch();
          console.log(swapStatus);
          setSwapStatus(SWAP_STATUS_IDLE);
          const walletAccount = getAccount(config);
          console.log("walletAccount: ", walletAccount);
          getGameBalanceOfWallet(walletAccount.address);
          openNotification(amount / 1000000000000000000n);
        },
      });
    } catch (error) {
      // console.log("error", error);
    }
  };

  const handleSwap = async (amount) => {
    try {
      setSwapStatus(SWAP_STATUS_ONGOING);
      // let swapAmount = BigInt(amount * 1000000000000000000);
      const creApprovalAmount = ethers.parseUnits(amount.toString(), 18);

      // const formattedAmount = ethers.parseUnits(swapAmount.toString(), 18);
      // console.log("formattedAmount: ", formattedAmount);
      // console.log('activeTokenAddress', activeTokenAddress);
      const approval = await writeContract(config, {
        abi: creAbi,
        address: activeTokenAddress,
        functionName: "approve",
        args: [contractGameAddress, creApprovalAmount],
      });
      const unwatchApproval = watchContractEvent(config, {
        address: activeTokenAddress,
        abi: creAbi,
        eventName: "Approval",
        onLogs(logs) {
          console.log("CRE Approved!", logs[0].data);
          unwatchApproval();
          swapSLG(creApprovalAmount);
        },
      });
    } catch (error) {
      // console.log("error", error);
      setSwapStatus(SWAP_STATUS_IDLE);
    }
  };

  const card_top_fn = () => {
    // console.log("card_top_fn");
  };

  const max_fn = () => {
    setInput_info(CREAmount);
  };

  const input_fn = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setInput_info(event.target.value);
    }
  };

  const onSelectChange = (e) => {
    setOptStatus(e.target.value);
  };

  const openNotification = (placement) => {
    const gameAmount = Number(placement) * 11.4196394;
    notification.open({
      message: `Swap Successful!`,
      description: `${placement} ${activeToken} has been swapped to ${gameAmount} GAME`,
      placement: "bottomRight",
      duration: 10,
    });
  };

  return (
    <>
      <section className="page2 fit-out">
        <div className="swapU row-out">
          <div className="swap">Please Swap with a Browser on a Computer</div>
        </div>
        <div className="title">Swap</div>
        <div className="title-label">{token_info} to GAME</div>
        <form className="form" action="#" method="get">
          <div className="top">
            <div className="card type1">
              <div className="ellipse">
                <img className="wh-all" src={ellipse} alt="" />
              </div>
              <div className="right">
                <div className="label">{optStatus}</div>
                {/* <select
                  className="label"
                  value={optStatus}
                  onChange={onSelectChange}
                >
                  {optData.map((opt) => (
                    <option key={opt}>{opt}</option>
                  ))}
                </select> */}
                <div className="value">{CREAmount}</div>
              </div>
            </div>
            <div className="card type2">
              <div className="ellipse">
                <img className="wh-all" src={ellipse2} alt="" />
              </div>
              <div className="right">
                <div className="label">GAME</div>
                <div className="value">{GAMEAmount}</div>
              </div>
            </div>
            <div className="disable_h5 icon" onClick={card_top_fn}>
              <img className="wh-all" src={card_top_icon} alt="" />
            </div>
          </div>
          <div className="bottom">
            <label className="input1">
              <div className="label">{token_info} Amount</div>
              <div className="input-box">
                <div className="prefix-icon"></div>
                <input
                  placeholder="Enter an amount"
                  className="input"
                  type="text"
                  value={input_info}
                  onChange={input_fn}
                />
                <div className="disable_h5 btn flex-in" onClick={max_fn}>
                  MAX
                </div>
              </div>
            </label>
            <div className="hr">
              <div className="line"></div>
              <img className="icon" src={card_bottom_icon} alt="" />
            </div>
            <div className="row-out col-in">
              <div className="amount">
                <div className="label-box">
                  <div className="label">Game Balance</div>
                  <div className="label1">{ratioText}</div>
                </div>
                <div className="value">{GAMEOwned}</div>
              </div>
              {/* openNotification('bottomRight') */}
              <div
                className={`disable_h5 swap flex-in ${
                  swapStatus === SWAP_STATUS_ONGOING
                    ? "SWAP_STATUS_ONGOING"
                    : ""
                }`}
                onClick={() => {
                  if (swapStatus === SWAP_STATUS_IDLE) {
                    if (!isNaN(parseFloat(input_info))) {
                      handleSwap(parseFloat(input_info));
                    } else {
                      notification.info({
                        message: `input a valid number`,
                        placement: "bottomRight",
                        duration: 10,
                      });
                    }
                  }
                }}
              >
                Swap
              </div>
            </div>
            <div className="disable_h5 submit-btn">
              <w3m-button className="w3m-button wh-all">
                Connect Wallet
              </w3m-button>
              <div
                className={`btn ${
                  swapStatus === SWAP_STATUS_ONGOING
                    ? "SWAP_STATUS_ONGOING"
                    : ""
                }`}
              >
                {btn_info}
              </div>
            </div>
          </div>
        </form>
      </section>
      {/* {showPop1 && (
        <Pop1
          onClose={() => setShowPop1(false)}
          account={account}
          onAccountConnected={handleAccountConnected}
        />
      )} */}
    </>
  );
};

export default Page2;
