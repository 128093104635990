// import { useState } from "react";

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { createWeb3Modal } from "@web3modal/wagmi/react";

import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { config } from "./config";

import "./App.css";
// import Page1 from "./components/page1/page1";
import Page2Cre from "./components/page2_CRE/page2";
import Page2Slg from "./components/page2_SLG/page2";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "0455f8bc86f40e4f7a5b6ba24aa1e20d";

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});

function App({ children }) {
  // const [showNext, setShowNext] = useState(false);
  // Initialize Web3
  // const web3 = new Web3("http://localhost:8545");

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        {children}
        <div className="App">
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/SLG" />} />
              <Route path="/SLG" element={<Page2Slg />} />

              {/*<Route path="/CRE" element={<Page2Cre />} />*/}
            </Routes>
          </Router>
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
